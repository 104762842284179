<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import {useToast} from "vue-toast-notification";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormSelectClient from "../../Components/FormSelectClient.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import FormTextField from "../../Components/FormTextField.vue";
import axios from "axios";
import FormMultiSelect from "../../Components/FormMultiSelect.vue";
import {userIsAdmin, userIsBackOffice} from "../../Utils/userRoleUtils.js";
import ModalEngineDetails from "../../Components/ModalEngineDetails.vue";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();

const loading = ref(false)
const isInitialized = ref(false)
const form = reactive( {
    client_id: null,
    center_id: null,
    status: null,
    reason_for_loss: null,
    tags: [],
    vehicle_brand_id: null,
    vehicle_model_id: null,
    vehicle_version_id: null,
    vehicle_engine_id: null,
    car_stage: null,
    contact_reason: null,
    vehicle_type: null,
    notes: null,
    request_origin: null,
    vin: null,
    license_plate: null,
    vehicle_year: null,
    mileage: null,
    spark_plug_mileage: null,
    coil_mileage: null,
    octane_rating: null,
    price: null,
    currency: null
})
const centerPreSelected = ref([])
const clientPreSelected = ref([])
const tagsPreSelected = ref([])
const errors = ref(null)
function updateClientRequest() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (key === 'tags') {
            return value.forEach((tag) => {
                formData.append(`${key}[]`, tag.id);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/client-requests/" + router.currentRoute.value.params.id, formData)
        .then(() => {
            $toast.success('Demande client modifiés avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function getClientRequest() {
    loading.value = true;
    axios.get("/api/client-requests/" + router.currentRoute.value.params.id)
        .then(async response => {
            //Populate the configurator
            if (response.data.data.vehicle_type !== null) {
                form.vehicle_type = response.data.data.vehicle_type;
                await getCarBrands();
            }
            if (response.data.data.vehicle_brand_id !== null) {
                form.vehicle_brand_id = response.data.data.vehicle_brand_id;
                await getCarModels();
            }
            if (response.data.data.vehicle_model_id !== null) {
                form.vehicle_model_id = response.data.data.vehicle_model_id;
                await getCarVersions();
            }
            if (response.data.data.vehicle_version_id !== null) {
                form.vehicle_version_id = response.data.data.vehicle_version_id;
                form.vehicle_engine_id = response.data.data.vehicle_engine_id;
                await getCarEngines();
            }

            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'center') {
                    centerPreSelected.value = value;
                    return;
                }
                if (key === 'client') {
                    clientPreSelected.value = value;
                    return;
                }
                if (key === 'tags') {
                    tagsPreSelected.value = value;
                    return;
                }
                if (Object.hasOwn(form, key) && key !== 'address') {
                    form[key] = value;
                }
            });
            loading.value = false;
            isInitialized.value = true;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getClientRequest()
})

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_brand_id = null;
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carModels.value = [];
            if (form.vehicle_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?vehicle_brand_id=' + form.vehicle_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carVersions.value = [];
            if (form.vehicle_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?vehicle_model_id=' + form.vehicle_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_version_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            carEngines.value = [];
            form.vehicle_engine_id = null;
            if (form.vehicle_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&vehicle_version_id=' + form.vehicle_version_id)
            .then(response => {
                response.data.data.forEach(engine => {
                    carEngines.value.push({
                        text: engine.data.power_type_label + ' - ' + engine.data.name + ' - ' + engine.data.horsepower + 'ch / ' + engine.data.torque + 'Nm',
                        value: engine.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function showModalEngine() {
    const modaleEngineDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEngineDetails'))
    modaleEngineDetails.show();
}

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="updateClientRequest" v-show="!loading">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations demande</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-select-client
                                :errors="errors"
                                label="Client"
                                name="client_id"
                                :required="true"
                                :data="form.client_id"
                                @update:field="form.client_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un client"
                                :client-pre-selected="clientPreSelected"
                            />


                            <form-select-center
                                :errors="errors"
                                label="Centre"
                                name="center_id"
                                :required="true"
                                :data="form.center_id"
                                @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un centre"
                                :center-pre-selected="centerPreSelected"
                            />

                            <form-select-field
                                name="status_request"
                                label="Statut"
                                :label-hidden="false"
                                @update:field="form.status = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.status"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'À traiter', value: 'pending' },
                                    { text: 'Attente d\'information', value: 'awaiting_information' },
                                    { text: 'Répondu', value: 'responded' },
                                    { text: 'RDV programmé', value: 'appointment_scheduled' },
                                    { text: 'RDV en cours', value: 'appointment_scheduled' },
                                    { text: 'Gagné', value: 'won' },
                                    { text: 'Client non présenté', value: 'no_show' },
                                    { text: 'Perdu', value: 'lost' },
                                    { text: 'Fermé', value: 'closed' }
                                ]"
                            />

                            <form-select-field
                                name="request_origin"
                                label="Origine demande"
                                :label-hidden="false"
                                @update:field="form.request_origin = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.request_origin"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Comptoir', value: 'Comptoir' },
                                    { text: 'Événement', value: 'Événement' },
                                    { text: 'Influenceur', value: 'Influenceur' },
                                    { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                    { text: 'RingOver', value: 'RingOver' },
                                    { text: 'ADS', value: 'ADS' },
                                    { text: 'Site web', value: 'Site web' },
                                    { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                    { text: 'Campagne SMS', value: 'Campagne SMS' },
                                    { text: 'Campagne Mail', value: 'Campagne Mail' },
                                    { text: 'Parrainage', value: 'Parrainage' },
                                    { text: 'Landing page', value: 'Landing page' },
                                ]"
                            />

                            <form-select-field
                                v-show="form.status === 'lost'"
                                name="reason_for_loss"
                                label="Raison de la perte"
                                :label-hidden="false"
                                @update:field="form.reason_for_loss = $event"
                                :errors="errors"
                                :required="form.status === 'lost'"
                                :data="form.reason_for_loss"
                                bs-class="col-xl-12"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Doublon', value: 'Doublon' },
                                    { text: 'Plus de réponse', value: 'Plus de réponse' },
                                    { text: 'Prix', value: 'Prix' },
                                    { text: 'Concurrent', value: 'Concurrent' },
                                    { text: 'Délais', value: 'Délais' },
                                    { text: 'Perte de confiance', value: 'Perte de confiance' },
                                    { text: 'Hors sujet', value: 'Hors sujet' },
                                    { text: 'Garantie', value: 'Garantie' },
                                    { text: 'Assurance', value: 'Assurance' },
                                ]"
                            />

                            <form-select-field
                                name="contact_reason"
                                label="Raison contact"
                                :label-hidden="false"
                                @update:field="form.contact_reason = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.contact_reason"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Rendez-vous', value: 'Rendez-vous' },
                                    { text: 'Demande de rappel', value: 'Demande de rappel' },
                                    { text: 'Devis', value: 'Devis' },
                                    { text: 'Demande d’information', value: 'Demande d’information' },
                                    { text: 'Devenir franchisé', value: 'Devenir franchisé' },
                                    { text: 'Reprog. en Attente', value: 'Reprog. en Attente' },
                                    { text: 'Participer au Dev.', value: 'Participer au Dev.' },
                                    { text: 'Assistance', value: 'Assistance' },
                                    { text: 'JOBS', value: 'JOBS' },
                                    { text: 'Tournage vidéo Youtube', value: 'Tournage vidéo Youtube' },
                                    { text: 'Autre', value: 'Autre' }
                                ]"
                            />

                            <form-select-field
                                name="car_stage"
                                label="Prestation souhaitée"
                                :label-hidden="false"
                                @update:field="form.car_stage = $event"
                                :errors="errors"
                                :required="!userIsAdmin() && !userIsBackOffice()"
                                :data="form.car_stage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Reprogrammation stage 0', value: 'Reprogrammation stage 0' },
                                    { text: 'Reprogrammation stage 1', value: 'Reprogrammation stage 1' },
                                    { text: 'Reprogrammation stage 2', value: 'Reprogrammation stage 2' },
                                    { text: 'Reprogrammation stage 3', value: 'Reprogrammation stage 3' },
                                    { text: 'Boîte de vitesses', value: 'Boîte de vitesses' },
                                    { text: 'Conversion E85', value: 'Conversion E85' },
                                    { text: 'Conversion E85+', value: 'Conversion E85+' },
                                    { text: 'Pièces performance', value: 'Pièces performance' },
                                    { text: 'Entretien', value: 'Entretien' },
                                    { text: 'Passage au banc', value: 'Passage au banc' },
                                    { text: 'Esthétique', value: 'Esthétique' },
                                    { text: 'Diagnostic', value: 'Diagnostic' },
                                ]"
                            />

                            <form-numeric-field
                                name="price"
                                label="Prix"
                                :label-hidden="false"
                                @update:field="form.price = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.price"
                                bs-class="col-md-6"
                                :min="0"
                                :step="0.01"
                            />

                            <form-select-field
                                name="currency"
                                label="Devise"
                                :label-hidden="false"
                                @update:field="form.currency = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.currency"
                                bs-class="col-md-6"
                                :liste-options="[
                                    { text: 'EUR', value: 'EUR' },
                                ]"
                            />

                            <form-multi-select
                                :errors="errors"
                                label="Tags"
                                name="tags"
                                :required="false"
                                :data="form.tags"
                                @update:field="form.tags = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un ou plusieurs tags"
                                :options-pre-selected="tagsPreSelected"
                                :liste-options="[
                                    { name: 'Promotion', id: 'Promotion' },
                                    { name: 'ECU en dev', id: 'ECU en dev' },
                                    { name: 'SAV', id: 'SAV' },
                                    { name: 'Remise Prépa', id: 'Remise Prépa' },
                                    { name: 'Remise origine', id: 'Remise origine' },
                                    { name: 'Franchise', id: 'Franchise' },
                                    { name: 'Jobs', id: 'Jobs' },
                                    { name: 'Jeux-concours', id: 'Jeux-concours' },
                                    { name: 'Doublon', id: 'Doublon' },
                                    { name: 'Tournage vidéo Youtube', id: 'Tournage vidéo Youtube' }
                                ]"
                            />

                            <form-textarea
                                name="notes"
                                label="Notes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="2"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-car me-2 align-middle fs-4"></i>Véhicule concerné</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-select-field
                                name="vehicle_type"
                                label="Type véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_type = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_type"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                :liste-options="[
                                    { text: 'Voiture', value: 'car' },
                                    { text: 'Agricole', value: 'agricultural' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'truck' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Réinitialiser configurateur', value: null },
                                ]"
                            />

                            <form-select-field
                                name="vehicle_brand_id"
                                label="Marques"
                                :label-hidden="false"
                                @update:field="form.vehicle_brand_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_brand_id"
                                bs-class="col-xl-6"
                                :liste-options="carBrands"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :disabled="form.vehicle_type === null"
                            />

                            <form-select-field
                                name="vehicle_model_id"
                                label="Modèles"
                                :label-hidden="false"
                                @update:field="form.vehicle_model_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_model_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_brand_id === null"
                                :liste-options="carModels"
                                :placeholder="form.vehicle_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                            />

                            <form-select-field
                                name="vehicle_version_id"
                                label="Versions"
                                :label-hidden="false"
                                @update:field="form.vehicle_version_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_version_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_model_id === null"
                                :liste-options="carVersions"
                                :placeholder="form.vehicle_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                            />

                            <form-select-field
                                name="vehicle_engine_id"
                                label="Motorisation"
                                :label-hidden="false"
                                @update:field="form.vehicle_engine_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_engine_id"
                                bs-class="col-xl-12"
                                :disabled="form.vehicle_version_id === null"
                                :liste-options="carEngines"
                                :placeholder="form.vehicle_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                :margin="form.vehicle_engine_id === null ? 'mb-3' : 'mb-2'"
                            />
                            <div class="col-md-12 mb-3" v-if="form.vehicle_engine_id">
                                <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showModalEngine()">Fiche moteur</button>
                                <router-link :to="{ name: 'chiptuning.show', params:{ id: form.vehicle_engine_id } }" class="btn btn-sm btn-secondary bg-gradient waves-effect waves-light ms-1" target="_blank">Fiche détaillée</router-link>
                            </div>

                            <form-text-field
                                name="vin"
                                label="VIN"
                                :label-hidden="false"
                                @update:field="form.vin = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vin"
                                bs-class="col-md-6"
                                :show-char-count="true"
                                :max-length="17"
                            />

                            <form-text-field
                                name="license_plate"
                                label="Immatriculation"
                                :label-hidden="false"
                                @update:field="form.license_plate = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.license_plate"
                                bs-class="col-md-6"
                            />

                            <form-numeric-field
                                name="vehicle_year"
                                label="Année du véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_year = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_year"
                                bs-class="col-md-6"
                                :step="1"
                                :min="1900"
                                :max="new Date().getFullYear()"
                            />

                            <form-numeric-field
                                name="mileage"
                                label="Kilométrage"
                                :label-hidden="false"
                                @update:field="form.mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.mileage"
                                bs-class="col-md-6"
                                :step="1"
                                :min="0"
                            />

                            <form-select-field
                                name="spark_plug_mileage"
                                label="Kilométrage des bougies"
                                :label-hidden="false"
                                @update:field="form.spark_plug_mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.spark_plug_mileage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Moins de 20 000 km', value: 'Moins de 20 000 km' },
                                    { text: 'Entre 20 et 50 000 km', value: 'Entre 20 et 50 000 km' },
                                    { text: 'Plus de 50 000 km', value: 'Plus de 50 000 km' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <form-select-field
                                name="coil_mileage"
                                label="Kilométrage des bobines"
                                :label-hidden="false"
                                @update:field="form.coil_mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.coil_mileage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Moins de 20 000 km', value: 'Moins de 20 000 km' },
                                    { text: 'Entre 20 et 50 000 km', value: 'Entre 20 et 50 000 km' },
                                    { text: 'Plus de 50 000 km', value: 'Plus de 50 000 km' },
                                    { text: 'Je ne sais pas', value: 'Je ne sais pas' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <form-select-field
                                name="octane_rating"
                                label="Indice Octane"
                                :label-hidden="false"
                                @update:field="form.octane_rating = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.octane_rating"
                                bs-class="col-xl-12"
                                :liste-options="[
                                    { text: 'SP95', value: 'SP95' },
                                    { text: 'SP98', value: 'SP98' },
                                    { text: 'SP100/102', value: 'SP100/102' },
                                    { text: 'E85', value: 'E85' },
                                    { text: 'E85/SP95 (Mix)', value: 'E85/SP95 (Mix)' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Modal vehicle info-->
                <modal-engine-details
                    :id-engine="form.vehicle_engine_id"
                    modal-id="modalEngineDetails"
                    />
            </div>
        </div>
    </form>
</template>

<style scoped>

</style>
