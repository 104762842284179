<script setup>

import Loader from "../../Components/Loader.vue";
import {onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import axios from "axios";
import FormSelectField from "../../Components/FormSelectField.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('updated_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    priority: 'asc',
    updated_at: 'asc',
    notifications_bo: 'asc',
    notifications_user: 'asc',
});

const errors = ref(null);
const filters = reactive( {
    type: null,
    vehicle_brand_id: null,
    vehicle_model_id: null,
    vehicle_version_id: null,
})
const filtersApplied = ref(true);
watch(url, () => {
    localStorage.setItem('chiptuningListUrl', url.value);
    localStorage.setItem('chiptuningSortingField', sortingField.value);
    localStorage.setItem('chiptuningSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('chiptuningSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('chiptuningQuery', query.value);
    localStorage.setItem('chiptuningFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('chiptuningListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
async function loadStoredSettings() {
    url.value = localStorage.getItem('chiptuningListUrl');
    sortingField.value = localStorage.getItem('chiptuningSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('chiptuningSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('chiptuningSearchLoaded') === 'true';
    query.value = localStorage.getItem('chiptuningQuery') ?? '';
    filtersApplied.value = localStorage.getItem('chiptuningFiltersApplied') === 'true';

    const savedFiltersString = localStorage.getItem('chiptuningFilters');
    if (savedFiltersString) {
        const savedFilters = JSON.parse(savedFiltersString);
        Object.assign(filters, savedFilters);

        if (filters.type !== null) {
            await getCarBrands();
        }
        if (filters.vehicle_brand_id !== null) {
            await getCarModels();
        }
        if (filters.vehicle_model_id !== null) {
            await getCarVersions();
        }
    }
}


function setDefaultSettings() {
    url.value = '/api/chiptuning?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const chiptuning = ref([]);
function loadData(urlToFetch) {
    chiptuning.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response) {
    chiptuning.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('chiptuningQuery', query.value);
}, 250);
const performSearch = async ( urlPerformSearch = null ) => {
    chiptuning.value = [];
    loading.value = true;
    let searchUrl = '/api/search/chiptuning?query=' + encodeURIComponent(query.value);
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let newUrl = new URL(searchUrl, window.location.origin);
    // Update filters
    filters.type
        ? newUrl.searchParams.set('vehicle_type', filters.type)
        : newUrl.searchParams.delete('type');

    filters.vehicle_brand_id
        ? newUrl.searchParams.set('vehicle_brand_id', filters.vehicle_brand_id)
        : newUrl.searchParams.delete('vehicle_brand_id');

    filters.vehicle_model_id
        ? newUrl.searchParams.set('vehicle_model_id', filters.vehicle_model_id)
        : newUrl.searchParams.delete('vehicle_model_id');

    filters.vehicle_version_id
        ? newUrl.searchParams.set('vehicle_version_id', filters.vehicle_version_id)
        : newUrl.searchParams.delete('vehicle_version_id');

    axios.get(newUrl.href)
        .then(response => {
            paginationAndDataMapping(response.data);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    chiptuning.value = [];
    let newUrl = '/api/chiptuning?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (filters.vehicle_brand_id) {
        newUrl += '&vehicle_brand_id=' + filters.vehicle_brand_id;
    }
    if (filters.vehicle_model_id) {
        newUrl += '&vehicle_model_id=' + filters.vehicle_model_id;
    }
    if (filters.vehicle_version_id) {
        newUrl += '&vehicle_version_id=' + filters.vehicle_version_id;
    }

    loadData(newUrl);
    searchLoaded.value = false;
};


function applyFilter(){

    let newUrl = new URL(url.value, window.location.origin);

    Object.entries(filters).forEach(([key, value]) => {
        if (value !== null) {
            newUrl.searchParams.set(key, value);
        } else {
            newUrl.searchParams.delete(key);
        }
    });

    filtersApplied.value = true;

    localStorage.setItem('chiptuningFilters', JSON.stringify(filters));
    localStorage.setItem('chiptuningFiltersApplied', filtersApplied.value.toString());

    loadData(newUrl.href);
}

watch(
    () => [filters.type, filters.vehicle_brand_id, filters.vehicle_model_id, filters.vehicle_version_id],
    () => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

watch(
    () => filters.type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            filters.vehicle_brand_id = null;
            filters.vehicle_model_id = null;
            filters.vehicle_version_id = null;
            await getCarBrands();
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    carBrands.value.push({
        text: 'Toutes les marques',
        value: null
    })
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + filters.type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => filters.vehicle_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            filters.vehicle_model_id = null;
            filters.vehicle_version_id = null;
            await getCarModels();
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    carModels.value.push({
        text: 'Tous les modèles',
        value: null
    })
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?vehicle_brand_id=' + filters.vehicle_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => filters.vehicle_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            filters.vehicle_version_id = null;
            await getCarVersions();
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    carVersions.value.push({
        text: 'Toutes les versions',
        value: null
    })
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?vehicle_model_id=' + filters.vehicle_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1">Tous les moteurs</h5>
                    </div>
                </div>
                <!--Search-->
                <div class="card-body border border-dashed border-end-0 border-start-0">
                    <form>
                        <div class="row g-3 justify-content-between">
                            <div class="col-xxl-5 col-sm-9">
                                <div class="search-box">
                                    <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchTickets">
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div class="col-xxl-1 col-sm-3">
                                <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <!--Filters-->
                <div class="card-body">
                    <form>
                        <div class="row g-3">
                            <form-select-field
                                name="filters.type"
                                label="Type"
                                :label-hidden="true"
                                @update:field="filters.type = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.type"
                                bs-class="col-xxl-3 col-sm-6"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les types"
                                :liste-options="[
                                    { text: 'Tous les types', value: null },
                                    { text: 'Agricole', value: 'agricultural' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'truck' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Voiture', value: 'car' },
                                ]"
                                margin="mb-0"
                            />
                            <form-select-field
                                name="filters.vehicle_brand_id"
                                label="Marques"
                                :label-hidden="true"
                                @update:field="filters.vehicle_brand_id = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.vehicle_brand_id"
                                bs-class="col-xxl-3 col-sm-6"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                :placeholder="filters.type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :liste-options="carBrands"
                                margin="mb-0"
                            />
                            <form-select-field
                                name="filters.vehicle_model_id"
                                label="Modèles"
                                :label-hidden="true"
                                @update:field="filters.vehicle_model_id = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.vehicle_model_id"
                                bs-class="col-xxl-3 col-sm-6"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                :placeholder="filters.vehicle_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                                :liste-options="carModels"
                                margin="mb-0"
                            />

                            <form-select-field
                                name="filters.vehicle_version_id"
                                label="Versions"
                                :label-hidden="true"
                                @update:field="filters.vehicle_version_id = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.vehicle_version_id"
                                bs-class="col-xxl-3 col-sm-6"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                :placeholder="filters.vehicle_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                                :liste-options="carVersions"
                                margin="mb-0"
                            />

                        </div>
                    </form>
                </div>
                <!--Table-->
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1" scope="col">Marque</th>
                                    <th class="col-1" scope="col">Modèle</th>
                                    <th class="col-1" scope="col">Version</th>
                                    <th class="col-1" scope="col">Carburant</th>
                                    <th class="col-1" scope="col">Motorisation</th>
                                    <th class="col-1" scope="col">Puissance - gain</th>
                                    <th class="col-1" scope="col">Type</th>
                                    <th class="col-1 text-center" scope="col">Stage 1</th>
                                    <th class="col-1 text-center" scope="col">Stage 2</th>
                                    <th class="col-1 text-center" scope="col">E85</th>
                                    <th class="col-1 text-center" scope="col">E85+</th>
                                    <th class="col-1 text-center" scope="col">Détails</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(engine, index) in chiptuning" :key="index">
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <img :src="engine.data?.vehicle_brand?.data.logo" alt="" class="avatar-xs rounded">
                                            </div>
                                            <div class="flex-grow-1 ms-2 name">
                                                {{ engine.data?.vehicle_brand_name }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ engine.data.vehicle_model_name }}</td>
                                    <td>{{ engine.data.vehicle_version_name }}</td>
                                    <td>{{ engine.data.power_type_label }}</td>
                                    <td>{{ engine.data.name }}</td>
                                    <td>{{ engine.data.horsepower }}<span class="text-muted"> ch</span> - {{ engine.data.torque }}<span class="text-muted"> Nm</span></td>
                                    <td>{{ engine.data.vehicle_brand.data.type_label }}</td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.stage1_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.stage2_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.e85_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge fs-11 bg-success-subtle text-success" v-if="engine.data.e85plus_available">Oui</span>
                                        <span class="badge fs-11 bg-danger-subtle text-danger" v-else>Non</span>
                                    </td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'chiptuning.show', params:{ id: engine.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="chiptuning.length === 0 && !loading">
                                    <td colspan="12" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="12" v-else>Aucun moteur...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="12">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
