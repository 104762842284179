<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {onBeforeMount, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import {api as viewerApi} from "v-viewer";
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const loading = ref(true)
const media = ref(null)
const imagesToPreview = ref([])
import axios from "axios";
function getMedia() {
    loading.value = true;
    axios.get("/api/social-network-media/" + router.currentRoute.value.params.id)
        .then(response => {
            media.value = response.data;
            loading.value = false;
            media.value.data.attachments.forEach((attachment) => {
                if (isImage(attachment.data)) {
                    imagesToPreview.value.push(attachment.data.path);
                }
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeMount(() => {
    getMedia()
})

function isImage(data) {
    const imageMimeTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const fileExtension = data.path.split('.').pop().toLowerCase();

    return imageMimeTypes.includes(data.mime_type) || ['jpg', 'jpeg', 'png', 'webp'].includes(fileExtension);
}

const previewImage = (imgPath) => {
    event.preventDefault()
    viewerApi({
        images: imagesToPreview.value,
        options: {
            toolbar: false,
            transition: false,
            loading: true,
            initialViewIndex: imagesToPreview.value.indexOf(imgPath)
        }
    })
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-12" v-if="!loading">
            <div class="tab-content text-muted">
                <div class="tab-pane fade show active" id="project-overview" role="tabpanel">
                    <div class="row">
                        <div class="col-xxl-9">

                            <div class="card">
                                <div class="card-body">
                                    <div class="text-center mb-3">
                                        <h5 class="h2 text-dark text-center mb-2 fs-22 mt-3">{{ media.data.title }}</h5>
                                        <div class="badge bg-info-subtle text-info fs-12">#{{ media.data.id}}</div>
                                    </div>
                                    <div class="mb-5">
                                        <div class="text-center">
                                            <img :src="'/format-image/'+media.data.thumbnail+'?w=800&h=470&fm=webp&fit=crop'" alt="" class="rounded shadow border img-fluid" width="800" height="470">
                                        </div>
                                    </div>

                                    <div class="card-text text-body fs-14 article-container" v-html="media.data.description"> </div>
                                </div>
                            </div>

                            <div class="card" v-if="media.data.attachments.length > 0">
                                <div class="card-header align-items-center d-flex border-bottom-dashed">
                                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                                </div>

                                <div class="card-body">

                                    <div class="vstack gap-2">

                                        <div class="border rounded border-dashed p-2" v-for="(attachment, index) in media.data.attachments" :key="index">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <a :href="attachment.data.path" target="_blank" download>
                                                    <!--If image, preview-->
                                                    <div class="avatar-sm" v-if="isImage(attachment.data)">
                                                        <img :src="'/format-image/'+attachment.data.path+'?w=48&h=48&fm=webp&fit=crop'" class="rounded" @click="previewImage(attachment.data.path)">
                                                    </div>
                                                    <div class="avatar-sm" v-else>
                                                        <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                            <!--Icon depending mime type-->
                                                            <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                                            <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                                            <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                            <i class="ri-file-text-line" v-else></i>
                                                        </div>
                                                    </div>
                                                    </a>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1">
                                                        <a href="#" target="_blank" class="text-body text-truncate d-block" @click="previewImage(attachment.data.path)">{{ attachment.data.name }}</a></h5>
                                                    <div>{{ attachment.data.size }}</div>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="d-flex gap-1">
                                                        <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none" download><i class="ri-download-2-line"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3">

                            <div class="card">
                                <div class="card-header align-items-center d-flex border-bottom-dashed">
                                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-book-edit-outline me-2 align-middle fs-4"></i>Publié par</h4>
                                    <div class="avatar-xs">
                                        <router-link :to="{ name: 'social-network-media.edit', params:{ id: media.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded"  v-if="media.can.update">
                                            <i class="ri-edit-2-fill"></i>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card-body text-center">
                                    <div class="position-relative d-inline-block">
                                        <img :src="'/format-image/'+media.data.user.data.profile_picture+'?w=86&h=86&fm=webp&fit=crop'" alt="" class="avatar-lg rounded-circle img-thumbnail shadow">
                                        <span class="contact-active position-absolute rounded-circle bg-success"><span class="visually-hidden"></span>
                                    </span></div>
                                    <h5 class="mt-4 mb-1">{{ media.data.user.data.first_name + ' ' + media.data.user.data.last_name}}</h5>
                                    <span :class="'badge fs-11 bg-'+media.data.user.data.role_badge_color+'-subtle  text-'+media.data.user.data.role_badge_color">{{ media.data.user.data.role_label_fr }}</span>
                                </div>

                                <div class="card-body">
                                    <div class="table-responsive table-card">
                                        <table class="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="fw-medium">Publié le</td>
                                                    <td class="text-muted">{{ media.data.created_at_fr }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Dernière modification</td>
                                                    <td class="text-muted">{{ media.data.updated_at_fr }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
