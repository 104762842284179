<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormPhoneField from "../../Components/FormPhoneField.vue";
import FormSelectCountry from "../../Components/FormSelectCountry.vue";
import FormSelectCenters from "../../Components/FormSelectCenters.vue";
import axios from "axios";
import ModalEngineDetails from "../../Components/ModalEngineDetails.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const centerPreSelected = ref(null)
const errors = ref(null)
const form = reactive( {
    gender: null,
    first_name: null,
    last_name: null,
    company_name: null,
    email: null,
    phone_number: null,
    phone_number_2: null,
    birth_date: null,
    subscribed_to_newsletter: false,
    notes: null,
    last_contact: null,
    preferred_contact_method: null,
    client_status: 'prospect',
    referral_source: 'Comptoir',
    recontact_required: false,
    recontact_reason: null,
    vehicle_type: null,
    vehicle_brand_id: null,
    vehicle_model_id: null,
    vehicle_version_id: null,
    vehicle_engine_id: null,
    centers: [],
    address: {
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        city: null,
        country: null,
    }
})

onBeforeMount(() => {
    if (authUser.value.centers.length >= 1) {
        centerPreSelected.value = {
            data: {
                id: authUser.value.centers[0].id,
                name: authUser.value.centers[0].name
            }
        }
    }
})
function createClient() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        //for address array, if all data inside are null or empty, pass
        if (key === 'address') {
            if (Object.values(value).every(x => (x === null || x === ''))) {
                return;
            }
            else {
                Object.entries(value).forEach(([itemKey, itemValue]) => {
                    if (itemValue !== null && itemValue !== '') {
                        formData.append(`${key}[${itemKey}]`, itemValue);
                    }
                });
                return;
            }
        }
        if (key === 'centers') {
            return value.forEach((center) => {
                formData.append(`${key}[]`, center.id);
            });
        }

        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/clients", formData)
        .then(response => {
            $toast.success('Client ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.push({name: 'clients.show', params: {id: response.data.data.id}});
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.vehicle_brand_id = null;
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_brand_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carModels.value = [];
            if (form.vehicle_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?vehicle_brand_id=' + form.vehicle_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_model_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carVersions.value = [];
            if (form.vehicle_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?vehicle_model_id=' + form.vehicle_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_version_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            carEngines.value = [];
            form.vehicle_engine_id = null;
            if (form.vehicle_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&vehicle_version_id=' + form.vehicle_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_label + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})


function showModalEngine() {
    const modaleEngineDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEngineDetails'))
    modaleEngineDetails.show();
}

</script>

<template>
<div>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="createClient">
        <div class="row" v-if="!loading">
            <div class="col-lg-6">
                <!--Principal infos-->
                <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-account-box me-2 align-middle fs-4"></i>Informations principales</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <form-select-field
                            name="gender"
                            label="Civilité"
                            :label-hidden="false"
                            @update:field="form.gender = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.gender"
                            bs-class="col-xl-2"
                            :disabled="false"
                            :liste-options="[
                                { text: 'M.', value: 'man' },
                                { text: 'Mme', value: 'woman' }
                            ]"
                        />

                        <form-text-field
                            name="first_name"
                            label="Prénom"
                            :label-hidden="false"
                            @update:field="form.first_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.first_name"
                            bs-class="col-xl-5"
                        />

                        <form-text-field
                            name="last_name"
                            label="Nom"
                            :label-hidden="false"
                            @update:field="form.last_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.last_name"
                            bs-class="col-xl-5"
                        />

                        <form-select-field
                            name="client_status"
                            label="Statut"
                            :label-hidden="false"
                            @update:field="form.client_status = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.client_status"
                            bs-class="col-xl-6"
                            :disabled="false"
                            :liste-options="[
                                { text: 'Autre', value: 'other' },
                                { text: 'Client', value: 'client' },
                                { text: 'Client douteux', value: 'client_doubtful' },
                                { text: 'Fournisseur', value: 'supplier' },
                                { text: 'Prospect', value: 'prospect' },
                                { text: 'Spam', value: 'spam' },
                                { text: 'Opportunité', value: 'opportunity' },
                            ]"
                        />

                        <form-phone-field
                            name="phone_number"
                            label="Téléphone"
                            :label-hidden="false"
                            @update:field="form.phone_number = $event"
                            :errors="errors"
                            :required="true"
                            bs-class="col-xl-6"
                            :data="form.phone_number"
                        />


                        <form-select-centers
                            :errors="errors"
                            label="Centre(s)"
                            name="centers"
                            :required="true"
                            :data="form.centers"
                            @update:field="form.centers = $event"
                            bs-class="col-xl-12"
                            :disabled="false"
                            :taggable="false"
                            :label-hidden="false"
                            placeholder="Sélectionnez un ou des centres"
                        />

                        <form-text-field
                            name="email"
                            label="Email"
                            :label-hidden="false"
                            @update:field="form.email = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.email"
                            bs-class="col-xl-6"
                            type="email"
                        />

                        <form-phone-field
                            name="phone_number_2"
                            label="Téléphone secondaire"
                            :label-hidden="false"
                            @update:field="form.phone_number_2 = $event"
                            :errors="errors"
                            :required="false"
                            bs-class="col-xl-6"
                            :data="form.phone_number_2"
                        />

                        <form-text-field
                            name="company_name"
                            label="Société"
                            :label-hidden="false"
                            @update:field="form.company_name = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.company_name"
                            bs-class="col-xl-12"
                            type="text"
                        />
                    </div>
                </div>
            </div>
                <!--Secondary infos-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations secondaires</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-select-field
                                name="referral_source"
                                label="Origine du contact"
                                :label-hidden="false"
                                @update:field="form.referral_source = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.referral_source"
                                bs-class="col-xl-12"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Comptoir', value: 'Comptoir' },
                                    { text: 'Événement', value: 'Événement' },
                                    { text: 'RingOver', value: 'RingOver' },
                                    { text: 'Influenceur', value: 'Influenceur' },
                                    { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                    { text: 'ADS', value: 'ADS' },
                                    { text: 'Site web', value: 'Site web' },
                                    { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                    { text: 'Bouche-à-oreille', value: 'Bouche à oreille' },
                                    { text: 'Démarchage', value: 'Démarchage' },
                                    { text: 'Achat Lead', value: 'Achat Lead' },
                                ]"
                            />

                            <!--<form-select-field-->
                            <!--    name="preferred_contact_method"-->
                            <!--    label="Méthode de contact préférée"-->
                            <!--    :label-hidden="false"-->
                            <!--    @update:field="form.preferred_contact_method = $event"-->
                            <!--    :errors="errors"-->
                            <!--    :required="false"-->
                            <!--    :data="form.preferred_contact_method"-->
                            <!--    bs-class="col-xl-6"-->
                            <!--    :disabled="false"-->
                            <!--    :liste-options="[-->
                            <!--        { text: 'Téléphone', value: 'Téléphone' },-->
                            <!--        { text: 'Email', value: 'Email' },-->
                            <!--        { text: 'SMS', value: 'SMS' }-->
                            <!--    ]"-->
                            <!--/>-->

                            <form-date-picker
                                name="birth_date"
                                label="Date de naissance"
                                :label-hidden="false"
                                @update:field="form.birth_date = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.birth_date"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-date-picker
                                name="last_contact"
                                label="Dernier contact"
                                :label-hidden="false"
                                @update:field="form.last_contact = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.last_contact"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-textarea
                                name="notes"
                                label="Notes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="2"
                            />

                            <form-switch
                                name="subscribed_to_newsletter"
                                label="Inscrit à la newsletter ?"
                                :label-hidden="false"
                                @update:field="form.subscribed_to_newsletter = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.subscribed_to_newsletter"
                                bs-class="col-xl-12"
                                :disabled="false"
                            />

                            <form-switch
                                name="recontact_required"
                                label="Client à recontacter ?"
                                :label-hidden="false"
                                @update:field="form.recontact_required = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.recontact_required"
                                bs-class="col-xl-12"
                                :disabled="false"
                            />

                            <form-text-field
                                v-if="form.recontact_required"
                                name="recontact_reason"
                                label="Raison de recontact"
                                :label-hidden="false"
                                @update:field="form.recontact_reason = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.recontact_reason"
                                bs-class="col-xl-12"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-car me-2 align-middle fs-4"></i>Véhicule principal</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-select-field
                                name="vehicle_type"
                                label="Type véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_type = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_type"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                :liste-options="[
                                    { text: 'Voiture', value: 'car' },
                                    { text: 'Agricole', value: 'agricultural' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'truck' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Réinitialiser configurateur', value: null },
                                ]"
                            />

                            <form-select-field
                                name="vehicle_brand_id"
                                label="Marques"
                                :label-hidden="false"
                                @update:field="form.vehicle_brand_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_brand_id"
                                bs-class="col-xl-6"
                                :liste-options="carBrands"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :disabled="form.vehicle_type === null"
                            />

                            <form-select-field
                                name="vehicle_model_id"
                                label="Modèles"
                                :label-hidden="false"
                                @update:field="form.vehicle_model_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_model_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_brand_id === null"
                                :liste-options="carModels"
                                :placeholder="form.vehicle_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                            />

                            <form-select-field
                                name="vehicle_version_id"
                                label="Versions"
                                :label-hidden="false"
                                @update:field="form.vehicle_version_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_version_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_model_id === null"
                                :liste-options="carVersions"
                                :placeholder="form.vehicle_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                            />

                            <form-select-field
                                name="vehicle_engine_id"
                                label="Motorisation"
                                :label-hidden="false"
                                @update:field="form.vehicle_engine_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_engine_id"
                                bs-class="col-xl-12"
                                :disabled="form.vehicle_version_id === null"
                                :liste-options="carEngines"
                                :placeholder="form.vehicle_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                :margin="form.vehicle_engine_id === null ? 'mb-3' : 'mb-2'"
                            />
                            <div class="col-md-12" v-if="form.vehicle_engine_id !== null">
                                <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showModalEngine()">Fiche moteur</button>
                            </div>

                        </div>
                    </div>
                </div>


                <!--Modal vehicle info-->
                <modal-engine-details
                    modal-id="modalEngineDetails"
                    :id-engine="form.vehicle_engine_id"
                />


                <!--address-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-map-marker-outline me-2 align-middle fs-4"></i>Adresse</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="address.address_line_1"
                                label="Adresse"
                                :label-hidden="false"
                                @update:field="form.address.address_line_1 = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.address_line_1"
                                bs-class="col-xl-12"
                            />

                            <form-text-field
                                name="address.address_line_2"
                                label="Complément d'adresse"
                                :label-hidden="false"
                                @update:field="form.address.address_line_2 = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.address_line_2"
                                bs-class="col-xl-12"
                            />

                            <form-text-field
                                name="address.postal_code"
                                label="Code postal"
                                :label-hidden="false"
                                @update:field="form.address.postal_code = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.postal_code"
                                bs-class="col-xl-4"
                            />

                            <form-text-field
                                name="address.city"
                                label="Ville"
                                :label-hidden="false"
                                @update:field="form.address.city = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.city"
                                bs-class="col-xl-4"
                            />

                            <form-select-country
                                name="address.country"
                                label="Pays"
                                :label-hidden="false"
                                @update:field="form.address.country = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.address.country"
                                bs-class="col-xl-4"
                            />
                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</template>

<style scoped>

</style>
