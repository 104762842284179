<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeUnmount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import {useToast} from "vue-toast-notification";
import FormTextarea from "../../Components/FormTextarea.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormNumericField from "../../Components/FormNumericField.vue";
import Editor from "@tinymce/tinymce-vue";
import FormSelectSearchUser from "../../Components/FormSelectSearchUser.vue";
import axios from "axios";
import ModalEngineDetails from "../../Components/ModalEngineDetails.vue";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(false)
const isInitialized = ref(true)
const form = reactive( {
    assigned_to_id: null,
    vehicle_type: null,
    vehicle_brand_id: null,
    vehicle_model_id: null,
    vehicle_version_id: null,
    vehicle_engine_id: null,
    vin: null,
    license_plate: null,
    vehicle_year: null,
    mileage: null,
    notes: null,
    description : null,
})
const errors = ref(null);
const tinyMceLoading = ref(true);

function createClientRequest() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/information-requests", formData)
        .then(response => {
            $toast.success('Demande d\'informations créée avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.push({ name: 'information-requests.show', params: { id: response.data.data.id } });
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_brand_id = null;
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_model_id = null;
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carModels.value = [];
            if (form.vehicle_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?vehicle_brand_id=' + form.vehicle_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.vehicle_version_id = null;
            form.vehicle_engine_id = null;
            carVersions.value = [];
            if (form.vehicle_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?vehicle_model_id=' + form.vehicle_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name,
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.vehicle_version_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            carEngines.value = [];
            form.vehicle_engine_id = null;
            if (form.vehicle_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&vehicle_version_id=' + form.vehicle_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_label + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function showModalEngine() {
    const modaleEngineDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEngineDetails'))
    modaleEngineDetails.show();
}

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="createClientRequest" v-if="!loading">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations demande</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md mb-3">
                                <label for="tinyEditor" class="form-label">Description demande<span class="text-danger"> *</span> </label>
                                <div class="position-relative">
                                    <div class="text-center my-2" v-if="tinyMceLoading">
                                        <div class="spinner-grow text-dark" role="status">
                                        </div>
                                        <br>
                                    </div>
                                    <Editor
                                        id="tinyEditor"
                                        v-model="form.description"
                                        :init="{
                                            license_key: 'gpl',
                                            base_url: '/tinymce/js/tinymce',
                                            suffix: '.min',
                                            contextmenu: false,
                                            language: 'fr_FR',
                                            menubar: false,
                                            content_style: 'body { font-family: \'Inter\', sans-serif; font-size: 13px; }',
                                            statusbar: false,
                                            plugins: ['lists', 'link', 'autoresize'],
                                            toolbar: 'bold italic underline | bullist numlist | link',
                                            paste_data_images: false,
                                            paste_as_text: false,
                                            autoresize_bottom_margin: 0,
                                            min_height: 250,
                                            max_height: 600,
                                            browser_spellcheck: true,
                                            init_instance_callback: function(editor) {
                                                tinyMceLoading = false;
                                            }
                                        }"
                                    />
                                    <span class="form-text text-muted mt-0">Enter = nouveau paragraphe / Maj + Enter = retour à la ligne.</span>
                                </div>
                            </div>

                            <form-textarea
                                name="notes"
                                label="Notes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="2"
                            />


                            <form-select-search-user
                                v-if="authUser.role === 'admin' || authUser.role === 'back_office'"
                                :errors="errors"
                                label="Assigné à"
                                name="assigned_to_id"
                                :required="false"
                                :data="form.assigned_to_id"
                                @update:field="form.assigned_to_id = ($event !== null ? $event.id : null)"
                                bs-class="col-md-12"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :user-pre-selected="null"
                                placeholder="Sélectionnez un utilisateur"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-car me-2 align-middle fs-4"></i>Véhicule concerné</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-select-field
                                name="vehicle_type"
                                label="Type véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_type = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_type"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                :liste-options="[
                                    { text: 'Voiture', value: 'car' },
                                    { text: 'Agricole', value: 'agricultural' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'truck' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Réinitialiser configurateur', value: null },
                                ]"
                            />

                            <form-select-field
                                name="vehicle_brand_id"
                                label="Marques"
                                :label-hidden="false"
                                @update:field="form.vehicle_brand_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_brand_id"
                                bs-class="col-xl-6"
                                :liste-options="carBrands"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :disabled="form.vehicle_type === null"
                            />

                            <form-select-field
                                name="vehicle_model_id"
                                label="Modèles"
                                :label-hidden="false"
                                @update:field="form.vehicle_model_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_model_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_brand_id === null"
                                :liste-options="carModels"
                                :placeholder="form.vehicle_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                            />

                            <form-select-field
                                name="vehicle_version_id"
                                label="Versions"
                                :label-hidden="false"
                                @update:field="form.vehicle_version_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_version_id"
                                bs-class="col-xl-6"
                                :disabled="form.vehicle_model_id === null"
                                :liste-options="carVersions"
                                :placeholder="form.vehicle_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                            />

                            <form-select-field
                                name="vehicle_engine_id"
                                label="Motorisation"
                                :label-hidden="false"
                                @update:field="form.vehicle_engine_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_engine_id"
                                bs-class="col-xl-12"
                                :disabled="form.vehicle_version_id === null"
                                :liste-options="carEngines"
                                :placeholder="form.vehicle_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                :margin="form.vehicle_engine_id === null ? 'mb-3' : 'mb-2'"
                            />
                            <div class="col-md-12 mb-3" v-if="form.vehicle_engine_id !== null">
                                <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showModalEngine()">Fiche moteur</button>
                            </div>

                            <form-text-field
                                name="license_plate"
                                label="Immatriculation"
                                :label-hidden="false"
                                @update:field="form.license_plate = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.license_plate"
                                bs-class="col-md-6"
                            />

                            <form-text-field
                                name="vin"
                                label="VIN"
                                :label-hidden="false"
                                @update:field="form.vin = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vin"
                                bs-class="col-md-6"
                                :show-char-count="true"
                                :max-length="17"
                            />

                            <form-numeric-field
                                name="vehicle_year"
                                label="Année du véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_year = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_year"
                                bs-class="col-md-6"
                                :step="1"
                                :min="1900"
                            />

                            <form-numeric-field
                                name="mileage"
                                label="Kilométrage"
                                :label-hidden="false"
                                @update:field="form.mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.mileage"
                                bs-class="col-md-6"
                                :step="1"
                                :min="0"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Modal vehicle info-->
                <modal-engine-details
                    modal-id="modalEngineDetails"
                    :id-engine="form.vehicle_engine_id"
                />
            </div>
        </div>
    </form>
</template>

<style scoped>

</style>
