<script setup>
import Loader from "./Loader.vue";
import {ref, watch} from "vue";
import axios from "axios";
import {useHttpErrorsHandler} from "../Composables/httpErrorsHandler.js";

const { httpErrorsHandler } = useHttpErrorsHandler();
const engine = ref({});
const loadingEngineDetails = ref(true);
const activeTab = ref('stage1Tab');

function getEngineDetails(idEngine) {
    loadingEngineDetails.value = true;
    axios.get('/api/chiptuning/' + idEngine)
        .then(response => {
            engine.value = response.data;
            loadingEngineDetails.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

//define props
const props = defineProps({
    idEngine: {
        type: Number,
        required: false,
        default: null
    },
    modalId: {
        type: String,
        required: false,
        default: 'modalEngineDetails'
    }
});
watch(() => props.idEngine, () => {
    if (props.idEngine !== null) {
        loadingEngineDetails.value = true;
        engine.value = {};
        getEngineDetails(props.idEngine);
    }
}, {immediate: true});

function sortOptions(options) {
    return options.sort((a, b) => a.name.localeCompare(b.name));
}

</script>

<template>
    <div :id="props.modalId" class="modal fade" tabindex="-1" :aria-labelledby="props.modalId">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="props.modalId+'Title'">
                        <i class="mdi mdi-engine-outline fs-22 me-2 align-middle"></i>Fiche moteur
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div class="modal-body">
                    <loader v-if="loadingEngineDetails" />
                    <div v-else>
                        <!--Car infos-->
                        <div>
                            <div class="mt-0 mx-auto text-center">
                                <img :src="engine.data.vehicle_brand.data.logo" alt="Logo" class="avatar-md">
                            </div>
                            <div class="mt-3 text-center">
                                <h4 class="mb-1">{{ engine.data.vehicle_brand_name}}</h4>
                                <p class="text-body fs-15 mb-0">
                                    {{ engine.data.vehicle_model_name }}<br>
                                    <span class="text-muted fs-13">
                                        {{ engine.data.vehicle_version_name }} - {{ engine.data.name }} - {{ engine.data.power_type_label }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark mb-4 mt-3" role="tablist">
                            <li class="nav-item" @click.prevent="activeTab = 'stage1Tab'">
                                <a class="nav-link stage1" :class="{ 'active': activeTab === 'stage1Tab' }" data-bs-toggle="tab" href="#stage1Tab" role="tab">
                                    Stage 1
                                </a>
                            </li>
                            <li class="nav-item" @click.prevent="activeTab = 'stage2Tab'">
                                <a class="nav-link stage2" :class="{ 'active': activeTab === 'stage2Tab' }" data-bs-toggle="tab" href="#stage2Tab" role="tab" v-if="engine.data.stage2_available">
                                    Stage 2
                                </a>
                            </li>
                            <li class="nav-item" @click.prevent="activeTab = 'stage3Tab'">
                                <a class="nav-link stage2" :class="{ 'active': activeTab === 'stage3Tab' }" data-bs-toggle="tab" href="#stage3Tab" role="tab" v-if="engine.data.stage3_available">
                                    Stage 3
                                </a>
                            </li>
                            <li class="nav-item" @click.prevent="activeTab = 'e85Tab'">
                                <a class="nav-link e85" :class="{ 'active': activeTab === 'e85Tab' }" data-bs-toggle="tab" href="#e85Tab" role="tab" v-if="engine.data.e85_available">
                                    E85
                                </a>
                            </li>
                            <li class="nav-item" @click.prevent="activeTab = 'e85+Tab'">
                                <a class="nav-link e85plus" :class="{ 'active': activeTab === 'e85+Tab' }" data-bs-toggle="tab" href="#e85+Tab" role="tab" v-if="engine.data.e85plus_available">
                                    E85+
                                </a>
                            </li>
                            <li class="nav-item" @click.prevent="activeTab = 'options'">
                                <a class="nav-link options" :class="{ 'active': activeTab === 'options' }" data-bs-toggle="tab" href="#options" role="tab">
                                    Options
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <!--Stage1-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'stage1Tab' }" id="stage1Tab" role="tabpanel">
                                <p class="text-body mb-3">
                                    La reprogrammation moteur Stage 1 consiste à optimiser la gestion du moteur pour améliorer les performances de votre véhicule et diminuer la consommation de carburant . C'est l'option parfaite pour une meilleure expérience de conduite sans modifications mécaniques.
                                </p>
                                <div class="table-responsive" v-if="engine.data.stage1_available">
                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="col-6"></th>
                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="fw-bold">Origine</td>
                                            <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold text-dark">Gain</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ engine.data.stage1_horsepower_gain }} ch</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ engine.data.stage1_torque_gain }}  Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold stage-1">Stage 1</td>
                                            <td class="text-center fw-bolder stage-1">{{ engine.data.stage1_horsepower_total }} ch</td>
                                            <td class="text-center fw-bolder stage-1">{{ engine.data.stage1_torque_total }} Nm</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">{{ engine.data.stage1_price }} €</span> <span class="text-muted">TTC</span>
                                    </p>
                                </div>
                                <div v-else>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">Bientôt disponible</span>
                                    </p>
                                </div>
                            </div>
                            <!--Stage2-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'stage2Tab' }" id="stage2Tab" role="tabpanel" v-if="engine.data.stage2_available">
                                <p class="text-body mb-3">
                                    Le Stage 2 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                                </p>
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="col-6"></th>
                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="fw-bold">Origine</td>
                                            <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold text-dark">Gain</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage2_horsepower_gain }} ch</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage2_torque_gain }}  Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold stage-2">Stage 2</td>
                                            <td class="text-center fw-bolder stage-2">{{ engine.data.stage2_horsepower_total }} ch</td>
                                            <td class="text-center fw-bolder stage-2">{{ engine.data.stage2_torque_total }} Nm</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ engine.data.stage2_price }} €</span> <span class="text-muted">TTC</span>
                                    </p>
                                </div>
                            </div>
                            <!--Stage3-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'stage3Tab' }" id="stage3Tab" role="tabpanel" v-if="engine.data.stage3_available">
                                <p class="text-body mb-3">
                                    Le Stage 3 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                                </p>
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="col-6"></th>
                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="fw-bold">Origine</td>
                                            <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold text-dark">Gain</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage3_horsepower_gain }} ch</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ engine.data.stage3_torque_gain }}  Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold stage-2">Stage 2</td>
                                            <td class="text-center fw-bolder stage-2">{{ engine.data.stage3_horsepower_total }} ch</td>
                                            <td class="text-center fw-bolder stage-2">{{ engine.data.stage3_torque_total }} Nm</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ engine.data.stage3_price }} €</span> <span class="text-muted">TTC</span>
                                    </p>
                                </div>
                            </div>
                            <!--E85-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'e85Tab' }" id="e85Tab" role="tabpanel" v-if="engine.data.e85_available">
                                <p class="text-body mb-3">
                                    La conversion E85 Flex-Fuel offre à votre véhicule la flexibilité de rouler au Bioéthanol, à l'essence, ou à un mélange des deux, sans contrainte. Cette adaptation réduit de moitié le coût du carburant et diminue notablement les émissions de CO2, réduisant ainsi l'impact environnemental.
                                </p>
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="col-6"></th>
                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="fw-bold">Origine</td>
                                            <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold text-dark">Gain</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold e-85">E85</td>
                                            <td class="text-center fw-bolder e-85">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center fw-bolder e-85">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85"></i> <span class=" fw-bold">{{ engine.data.e85_price }} €</span> <span class="text-muted">TTC</span>
                                    </p>
                                </div>
                            </div>
                            <!--E85+-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'e85+Tab' }" id="e85+Tab" role="tabpanel" v-if="engine.data.e85plus_available">
                                <p class="text-body mb-3">
                                    La conversion E85+ Flex-Fuel permet à votre véhicule de fonctionner avec du Bioéthanol, de l'essence, ou un mélange des deux carburants, tout en bénéficiant de l’augmentation de puissance d’un stage 1. En plus de réduire le coût du carburant de moitié, elle diminue aussi les émissions de CO2, tout en améliorant significativement les performances.
                                </p>
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="col-6"></th>
                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="fw-bold">Origine</td>
                                            <td class="text-center text-body">{{ engine.data.horsepower }} ch</td>
                                            <td class="text-center text-body">{{ engine.data.torque }} Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold text-dark">Gain</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ engine.data.e85plus_horsepower_gain }} ch</td>
                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ engine.data.e85plus_torque_gain }}  Nm</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-bold e-85plus">E85+</td>
                                            <td class="text-center fw-bolder e-85plus">{{ engine.data.e85plus_horsepower_total }} ch</td>
                                            <td class="text-center fw-bolder e-85plus">{{ engine.data.e85plus_torque_total }} Nm</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p class="fs-15">
                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85plus"></i> <span class=" fw-bold">{{ engine.data.e85plus_price }} €</span> <span class="text-muted">TTC</span>
                                    </p>
                                </div>
                            </div>
                            <!--Options-->
                            <div class="tab-pane" :class="{ 'active': activeTab === 'options' }" id="options" role="tabpanel">
                                <ul v-if="engine.data.options && engine.data.options.length > 0">
                                    <li class="mb-2" v-for="(option, indexOption) in sortOptions(engine.data.options)" :key="indexOption">
                                        <strong :title="option.description">{{ option.name }}</strong>
                                    </li>
                                </ul>
                                <p class="text-muted mb-0" v-else>Aucune option.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="!loadingEngineDetails">
                    <router-link :to="{ name: 'chiptuning.show', params:{ id: engine.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light" target="_blank">Fiche détaillée</router-link>
                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
